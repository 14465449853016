/* eslint-disable no-underscore-dangle */
//
import {combineReducers} from "redux";
import {modals} from "atg-modals/modalReducer";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";
import {limitsReducer as limits} from "@atg-responsible-gambling-shared/limits-domain";
import {shareReducer as shareNext} from "@atg-shop-next/share-data-access";
import mainNavbar from "@atg-navigation-shared/main-navbar-data-access/src/domain/mainNavbarReducer";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<any, any>({
        ...asyncReducers.root,

        activeBreakpoint,
        modals,

        alert: () => window._frameStore.getState().alert,
        accessToken: () => window._frameStore.getState().accessToken,
        auth: () => window._frameStore.getState().auth,
        limits,
        realityCheck: () => window._frameStore.getState().realityCheck,
        router: () => window._frameStore.getState().router,
        responsibleGamingHeader: () =>
            window._frameStore.getState().responsibleGamingHeader,
        user: () => window._frameStore.getState().user,
        verticals: () => window._frameStore.getState().verticals,
        shareNext,
        mainNavbar,
    });

export default createRootReducer;
